import { useEffect, useCallback } from 'react';
import anime from 'animejs';
import { useDispatch } from 'react-redux';

// import { getWidthNormalizedScale } from '../../lib/utils';
import { setAnimation as setAnimationAction } from '../../actions/SiteActions';

export const setAnimation = (animation) => {
    const dispatch = useDispatch(animation);
    const setAnimationState = useCallback(a => dispatch(setAnimationAction(a)), [dispatch]);
    useEffect(() => {
        setAnimationState(animation);
    }, []);
};

export const buttonSpinAnimation = (buttonRef) => {
    anime.timeline({ loop: true }).add({
        targets: [buttonRef.current],
        opacity: [0.1, 1],
        duration: 12000,
        easing: 'spring(1, 50, 30, 0)',
        delay: (el, i) => 1000 + 120 * (i + 1),
    });
};

export const introTextAnimation = (itemsRef) => {
    anime.timeline({ loop: false }).add({
        targets: itemsRef.current,
        scale: [0, 1],
        opacity: [0.1, 1],
        duration: 1200,
        easing: 'spring(1, 50, 30, 0)',
        delay: (el, i) => 1000 + 120 * (i + 1),
    });
};

export const endTextAnimation = ({ cyanRef, yellowRef, magentaRef }) => {
    // const duration = 2000;
    const easing = 'spring(2, 20, 80, 7)';
    // Simple type animation
    useEffect(() => {
        anime
            .timeline({ loop: false })
            .add(
                {
                    targets: cyanRef.current,
                    opacity: [0, 1],
                    translateY: ['-180px', '-3px'],
                    translateX: ['-180px', '-2px'],
                    easing,
                    delay: (el, i) => 1400 + 200 * (i + 1),
                },
                0,
            )
            .add(
                {
                    targets: yellowRef.current,
                    opacity: [0, 1],
                    translateY: ['-180px', '-2px'],
                    translateX: ['180px', '-1px'],
                    easing,
                    delay: (el, i) => 1400 + 200 * (i + 1),
                },
                150,
            )
            .add(
                {
                    targets: magentaRef.current,
                    opacity: [0, 1],
                    translateY: ['180px', '0px'],
                    translateX: ['0px', '0px'],
                    easing,
                    delay: (el, i) => 1400 + 200 * (i + 1),
                },
                300,
            );
    }, []);
};

export const lineAnimation = ({ started = false, containerRef, itemsRef }) => {
    // const size = getWidthNormalizedScale({ elementWidth: width, elementHeight: height });

    useEffect(() => {
        // Size and move the container
        anime({
            targets: [containerRef.current],
            // scale: 4,
            // scale: [0.8 * size.widthRatio, 1 * size.widthRatio],
            duration: 10000,
            // skew: 4,
            loop: true,
            direction: 'alternate',
            easing: 'easeOutQuad',
        });

        return () => {
            anime.remove([containerRef.current]);
        };
    }, [containerRef]);

    useEffect(() => {
        if (started) {
            anime.set(itemsRef.current, { opacity: 0 });
            // Recording animation
            const animation = anime
                .timeline()
                .add(
                    {
                        targets: itemsRef.current,
                        scale: [0.5, 1],
                        opacity: [0, 1],
                        easing: 'spring(1, 50, 30, 3)',
                        // stroke: 'url(#SVGID_LINE)',
                        delay: (el, i) => 154 * (i + 1),
                    },
                    0,
                )
                .add(
                    {
                        targets: itemsRef.current,
                        opacity: [1],
                        duration: 500,
                        easing: 'easeOutQuad',
                        stroke: '#ffff00',
                        y1: () => anime.random(0, 45),
                        y2: () => anime.random(45, 90),
                        delay: (el, i) => 20 * (i + 1),
                    },
                    10900,
                )
                .add(
                    {
                        targets: itemsRef.current,
                        scale: [1, 1.05],
                        duration: 5000,
                        easing: 'spring(1, 50, 30, 0)',
                        stroke: '#ff00ff',
                        y1: () => anime.random(0, 100),
                        y2: () => anime.random(0, 100),
                        delay: (el, i) => 20 * (i + 1),
                    },
                    12900,
                );

            animation.finished.then(() => {
                anime({
                    targets: itemsRef.current,
                    scale: 1.05,
                    stroke: ['#ff00ff', '#ffff00'],
                    easing: 'spring(1, 20, 80, 0)',
                    y1: () => anime.random(0, 45),
                    y2: () => anime.random(45, 90),
                    delay: (el, i) => 500 + 40 * (i + 1),
                    direction: 'alternate', // Is not inherited
                    loop: true,
                });
            });
        }
        return () => {
            anime.remove(itemsRef.current);
        };
    }, [started]);
};
