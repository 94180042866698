import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';

import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { uploadFile, postJSON } from '@folklore/fetch';

import PageMeta from '../partials/PageMeta';
import Player from '../partials/Player';
import SoundForm from '../partials/SoundForm';
import Button from '../buttons/Button';
import AcceptIcon from '../icons/Accept';
import CancelIcon from '../icons/Cancel';

import { setAnimation } from '../hooks/useAnimations';

import { setBlobURL as setBlobURLAction } from '../../actions/SiteActions';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/playback.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Enregistreuse de rires',
    },
    help: {
        id: 'content.playback.help',
        defaultMessage: 'Aidez-nous à catégoriser votre rire',
    },
    listen: {
        id: 'content.playback.listen',
        defaultMessage: 'Écoutez votre rire',
    },
    save: {
        id: 'content.playback.save',
        defaultMessage: 'Soumettre votre rire',
    },
    sending: {
        id: 'content.playback.sending',
        defaultMessage: 'Envoi du rire...',
    },
    cancel: {
        id: 'content.playback.cancel',
        defaultMessage: 'Rire à nouveau',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    history: AppPropTypes.history.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const PlaybackPage = ({ intl, history, urlGenerator }) => {
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState(null);
    const [location, setLocation] = useState({ latitude: null, longitude: null });

    const { sound, blobURL } = useSelector(s => s.site);
    const src = sound || blobURL || null;

    const dispatch = useDispatch();
    const setBlobUrl = useCallback(url => dispatch(setBlobURLAction(url)), [dispatch]);
    const clearData = useCallback(() => {
        setBlobUrl(null);
    });

    setAnimation({ name: 'Background' });
    // setAnimation({ name: 'Base' });

    const onSave = useCallback(() => {
        if (loading || !blobURL) return;
        setLoading(true);
        fetch(blobURL).then((r) => {
            r.blob()
                .then((blob) => {
                    uploadFile('/mediatheque/upload/audio', blob, {
                        processData: false,
                        contentType: false,
                    })
                        .then((data) => {
                            const {
                                latitude: lat,
                                longitude: lng,
                                address,
                                ...otherProps // eslint-disable-line
                            } = location;
                            postJSON('/api/sounds/save', {
                                data: {
                                    name: `Son ${moment()
                                        .locale('fr')
                                        .format('LLL')}`,
                                    source: 'Website',
                                    audio: data,
                                    language: intl.locale,
                                    gender,
                                    lat: `${lat}`,
                                    lng: `${lng}`,
                                    address,
                                },
                            })
                                .then(() => {
                                    clearData();
                                    setLoading(false);
                                    history.push(urlGenerator.route('end'));
                                })
                                .catch(() => {
                                    setLoading(false);
                                });
                        })
                        .catch(() => {
                            setLoading(false);
                        });
                })
                .catch(() => {
                    setLoading(false);
                });
        });
    }, [loading, blobURL, gender, location]);

    const onCancel = useCallback(() => {
        clearData();
        history.push(urlGenerator.route('record'));
    });

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.section}>
                <div className={styles.player}>
                    <Player
                        src={src}
                        className={styles.play}
                        label={intl.formatMessage({ ...messages.listen })}
                    />
                </div>
            </div>
            <div className={styles.section}>
                <h1>
                    <FormattedMessage {...messages.help} />
                </h1>
            </div>
            <div className={styles.section}>
                <SoundForm
                    gender={gender}
                    location={location}
                    onGenderChange={setGender}
                    onLocationChange={setLocation}
                />
            </div>
            <div className={styles.section}>
                <Button
                    className={classNames([styles.button, styles.cancel])}
                    innerClassName={classNames([styles.inner, styles.cancel])}
                    onClick={onCancel}
                    disabled={loading}
                >
                    <span>
                        <CancelIcon />
                    </span>
                    <span>
                        <FormattedMessage {...messages.cancel} />
                    </span>
                </Button>
                {blobURL ? (
                    <Button
                        className={classNames([styles.button, styles.save])}
                        innerClassName={classNames([styles.inner, styles.save])}
                        label={
                            loading
                                ? intl.formatMessage(messages.sending)
                                : intl.formatMessage(messages.save)
                        }
                        icon={<AcceptIcon />}
                        onClick={onSave}
                        disabled={loading}
                    />
                ) : null}
            </div>
        </div>
    );
};

PlaybackPage.propTypes = propTypes;

const WithIntlContainer = injectIntl(PlaybackPage);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);
const WithRouter = withRouter(WithUrlGeneratorContainer);

export default WithRouter;
