/* eslint-disable max-len */
import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';

import SVGContainer from './SVGContainer';

import styles from '../../../styles/animations/layered-animation.scss';

const ITEM_COUNT = 15;

const propTypes = {
    name: PropTypes.string,
    started: PropTypes.bool,
    delay: PropTypes.number,
    duration: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    variables: PropTypes.array, // eslint-disable-line
};

const defaultProps = {
    name: 'chose',
    started: false,
    delay: 3000,
    duration: 1000,
    width: null,
    height: null,
    variables: [],
};

const Complex = ({
    name, started, width, height, delay, duration, variables,
}) => {
    const targetsRef = useRef([]);

    const size = { width: 1366, height: 768 };
    const containerRef = useRef(null);
    const gradientRef = useRef(null);
    const itemsRef = useRef([]);
    const startColorsRef = useRef([]);
    const stopColorsRef = useRef([]);
    const previousShape = useRef(null);

    const iterations = [...Array(ITEM_COUNT).keys()];

    useEffect(() => {
        targetsRef.current = itemsRef.current.concat([]);
        anime
            .timeline({
                targets: [containerRef.current],
                loop: true,
                direction: 'alternate',
                delay,
                duration: 12000,
                easing: 'easeInOutSine',
            })
            .add({
                scale: 0.8,
                rotateZ: anime.random(-180, 180),
            });
    }, []);

    const createAnimation = useCallback(
        (timeline, i) => {
            const {
                colorScale, rotation, nextShape, destinationX, destinationY,
            } = variables[i];
            const snakeOpacity = anime.random(0.5, 1);
            const scale = anime.random(90, 100) / 100;

            previousShape.current = nextShape;

            const maxStart = 120 * targetsRef.current.length;

            // targetsRef.current.pop();
            // targetsRef.current.reverse();

            timeline
                .add(
                    {
                        targets: targetsRef.current.reverse(),
                        translateX: () => destinationX,
                        translateY: () => destinationY,
                        rotate: `${rotation}deg`,
                        opacity: snakeOpacity,
                        delay: anime.stagger(120, { start: 0 }),
                        scale,
                        easing: 'easeOutQuad',
                        // easing: 'spring(1, 100, 50, 12)',
                        // easing: 'easeOutElastic(1, 0.2)',
                        // scale: anime.stagger([0.7, 1]), // [{ value: 1.2 }, { value: 0.8 }],
                        // skew: anime.random(0, 10),
                        perspective: anime.random(100, 300),
                        d: [
                            {
                                value: nextShape,
                            },
                        ],
                    },
                    maxStart + i * 2000,
                )
                .add(
                    {
                        targets: [startColorsRef.current],
                        stopColor: (el, j) => colorScale(j / startColorsRef.current.length),
                        delay: anime.stagger(100, { start: 100 }),
                        easing: 'spring(1, 100, 50, 4)',
                    },
                    `-=${duration}`,
                )
                .add(
                    {
                        targets: [stopColorsRef.current],
                        delay: anime.stagger(100, { start: 100 }),
                        easing: 'spring(1, 100, 50, 4)',
                    },
                    `-=${duration}`,
                )
                .add(
                    {
                        targets: [stopColorsRef.current],
                        stopOpacity: anime.random(20, 100) / 100,
                    },
                    `-=${duration}`,
                );
        },
        [name],
    );

    const createTimeline = useCallback(() => {
        const timeline = anime.timeline({
            loop: false,
            duration,
            complete: () => {
                // targetsRef.current.pop();
                anime.remove([itemsRef.current, startColorsRef.current, stopColorsRef.current]);
                createTimeline(); // eslint-disable-line
            },
            easing: 'easeInOutQuad',
        });
        for (let i = 0; i < 15; i += 1) {
            createAnimation(timeline, i);
        }
    }, [delay, duration]);

    useEffect(() => {
        if (started) {
            anime.remove(itemsRef.current);
            anime.remove(startColorsRef.current);
            anime.remove(stopColorsRef.current);
            setTimeout(() => {
                createTimeline();
            }, delay);
        }
        return () => {
            anime.remove(itemsRef.current);
            anime.remove(startColorsRef.current);
            anime.remove(stopColorsRef.current);
        };
    }, [started, delay]);

    const actualWidth = Math.max(width, size.width);
    const actualHeight = Math.max(height, size.height);

    return (
        <div className={styles.container}>
            <SVGContainer width={width} height={height} size={size}>
                <g ref={containerRef}>
                    {iterations.map(i => (
                        <g key={`complex-gradient-${i}-${name}`}>
                            <linearGradient
                                id={`SVGID_COMPLEX_${i}_${name}`}
                                gradientUnits="userSpaceOnUse"
                                x1="97.451"
                                y1="219.5463"
                                x2="620.209"
                                y2="219.5463"
                                gradientTransform="matrix(-0.7071 0.7071 -0.7071 -0.7071 653.0718 156.6759)"
                            >
                                <stop
                                    ref={(el) => {
                                        startColorsRef.current[i] = el;
                                    }}
                                    offset="0"
                                    stopColor="#00FFFF"
                                    stopOpacity="0.2"
                                />
                                <stop
                                    ref={(el) => {
                                        stopColorsRef.current[i] = el;
                                    }}
                                    offset="0.9987"
                                    stopColor="#00FF00"
                                    stopOpacity="0.8"
                                />
                            </linearGradient>
                            <radialGradient
                                id={`SVGID_RADICAL_${i}_${name}`}
                                ref={gradientRef}
                                cx={actualWidth / 2}
                                cy={actualHeight / 2}
                                r={300}
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop
                                    offset="0"
                                    ref={(el) => {
                                        startColorsRef.current[i] = el;
                                    }}
                                    stopColor="#00FFFF"
                                />
                                <stop
                                    offset="1"
                                    ref={(el) => {
                                        stopColorsRef.current[i] = el;
                                    }}
                                    stopColor="#FFFFFF"
                                    stopOpacity="0"
                                />
                            </radialGradient>
                        </g>
                    ))}
                    {iterations.map(i => (
                        <path
                            key={`complex-path-${i}`}
                            ref={(el) => {
                                itemsRef.current[i] = el;
                            }}
                            d="M797.3,234.7c-56-56.4-162.8-79.3-261.8-8.6c-106.1,75.8-70.7,280.9,90.5,316.8 C755.6,571.9,944.3,382.8,797.3,234.7z"
                            fill={`url(#SVGID_RADICAL_${i}_${name})`}
                        />
                    ))}
                </g>
            </SVGContainer>
        </div>
    );
};

Complex.propTypes = propTypes;
Complex.defaultProps = defaultProps;

export default Complex;
