import React, {
    useState, useCallback, useEffect, useRef,
} from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import PageMeta from '../partials/PageMeta';
import AutoPlayer from '../partials/AutoPlayer';
import DateBox from '../icons/DateBox';

import * as AppPropTypes from '../../lib/PropTypes';

import { setAnimation as setAnimationAction } from '../../actions/SiteActions';

import styles from '../../../styles/pages/listen.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Le rire universel',
    },
    title: {
        id: 'content.listen.title',
        defaultMessage: 'À propos du rire universel',
    },
    description: {
        id: 'content.listen.description',
        defaultMessage:
            'Lorem ipsum dolorsit amet, consectetur adipiscing elit, seddo eiusmod tempor incididunt ut laboreet dolore magna aliqua.',
    },
    date: {
        id: 'content.listen.date',
        defaultMessage: '28 sept',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
};

// const getRandomInt = (minimum, maximum) => {
//     const min = Math.ceil(minimum);
//     const max = Math.floor(maximum);
//     return Math.floor(Math.random() * (max - min + 1)) + min;
// };

const ListenPage = ({ intl }) => {
    const [playing, setPlaying] = useState(false);
    const { voices } = useSelector(s => s.site);
    const [voice, setVoice] = useState(null);
    const items = useRef(voices);
    const dispatch = useDispatch();
    const setAnimation = useCallback(media => dispatch(setAnimationAction(media)), [dispatch]);

    const getRandomVoice = useCallback(
        () => items.current
            .filter(v => v.audio.url !== voice)
            .filter(v => items.current.indexOf(v.audio.url) === -1)
            .pop(),
        [items],
    );

    const changeTrack = useCallback(() => {
        // console.log('ct', playing);
        if (playing) {
            const newVoice = getRandomVoice();
            if (newVoice) {
                setVoice(newVoice.audio.url);
            } else {
                setPlaying(false);
            }
        }
    }, [playing, getRandomVoice, setVoice, setAnimation, setPlaying]);

    useEffect(() => {
        // console.log('on playing changed');
        changeTrack();
        if (playing) {
            setAnimation({ name: 'Complex', started: true });
        } else {
            setAnimation({ name: 'Complex', started: false });
        }
    }, [playing]);

    const onClick = useCallback(() => {
        // console.log('on click');
        setPlaying(!playing);
    }, [playing, setPlaying]);

    const onComplete = useCallback(() => {
        // console.log('on complete');
        changeTrack();
    }, []);

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.centered}>
                <div className={styles.section}>
                    <h1>{intl.formatMessage(messages.title)}</h1>
                    <p>{intl.formatMessage(messages.description)}</p>
                    <DateBox date={28} month="oct" />
                </div>
                <div className={styles.section}>
                    {voices.length > 0 ? (
                        <AutoPlayer
                            src={voice}
                            playing={playing}
                            onClick={onClick}
                            onComplete={onComplete}
                        />
                    ) : null}
                </div>
                <div className={styles.section} />
            </div>
        </div>
    );
};

ListenPage.propTypes = propTypes;

const WithIntlContainer = injectIntl(ListenPage);

export default WithIntlContainer;
