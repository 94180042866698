import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    highlights: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#000000',
    highlights: '#FFFFFF',
    size: 80,
    className: null,
};

const AutoPlay = ({
    color, highlights, size, className,
}) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={`${size}px`}
        height={`${size}px`}
        viewBox={`0 0 ${size} ${size}`}
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <path
            fill={color}
            d="M10.3,1.1C4.6-1.8,0,1.2,0,7.6v64.7c0,6.5,4.6,9.4,10.3,6.5l65.5-33.6c5.6-2.9,5.6-7.6,0-10.5L10.3,1.1z"
        />
        <g>
            <path
                fill={highlights}
                d="M32.5,52.1c-0.8,0.1-1.3,0.9-1.2,1.6c0.1,0.8,0.9,1.3,1.6,1.2c0.8-0.1,1.3-0.9,1.2-1.6
            C34,52.5,33.3,52,32.5,52.1z"
            />
            <path
                fill={highlights}
                d="M36.7,50.6c-0.7,0.4-0.9,1.2-0.5,1.9c0.4,0.7,1.2,0.9,1.9,0.5c0.7-0.4,0.9-1.2,0.5-1.9
            C38.3,50.5,37.4,50.3,36.7,50.6z"
            />
            <path
                fill={highlights}
                d="M40.3,47.9c-0.5,0.5-0.4,1.3,0.1,1.8c0.5,0.5,1.3,0.4,1.8-0.1c0.5-0.5,0.4-1.3-0.1-1.8
            C41.5,47.3,40.7,47.4,40.3,47.9z"
            />
            <path
                fill={highlights}
                d="M44.1,43.5c-0.6-0.2-1.2,0.1-1.5,0.7c-0.2,0.6,0.1,1.2,0.7,1.5c0.6,0.2,1.2-0.1,1.5-0.7
            C45,44.4,44.7,43.7,44.1,43.5z"
            />
            <path
                fill={highlights}
                d="M44.7,38.7c-0.5,0-1,0.4-1,1s0.4,1,1,1c0.5,0,1-0.4,1-1C45.7,39.2,45.2,38.7,44.7,38.7z"
            />
            <path
                fill={highlights}
                d="M44.2,35.7c0.4-0.2,0.7-0.6,0.5-1.1c-0.2-0.4-0.6-0.7-1.1-0.5c-0.4,0.2-0.7,0.6-0.5,1.1
            C43.2,35.6,43.7,35.8,44.2,35.7z"
            />
            <path
                fill={highlights}
                d="M41.9,31.1c0.3-0.2,0.3-0.7,0.1-1c-0.2-0.3-0.7-0.3-1-0.1c-0.3,0.2-0.3,0.7-0.1,1
            C41.2,31.3,41.6,31.3,41.9,31.1z"
            />
            <path
                fill={highlights}
                d="M35.2,24.7c-0.1-0.1-0.3-0.2-0.4-0.2c-3.9-1-8-0.4-11.6,1.6c-3.5,2.1-6,5.4-7,9.3C14,43.5,18.9,51.9,27.1,54
            c0.1,0,0.2,0,0.3,0c0.5,0,0.9-0.3,1-0.8c0.1-0.6-0.2-1.1-0.8-1.3c-3.4-0.9-6.2-3-8-6c-1.8-3-2.3-6.6-1.4-9.9c1.8-7,9-11.2,16-9.4
            c0,0,0,0,0,0L33,29.1l6.4-1.3L36.5,22L35.2,24.7z"
            />
        </g>
    </svg>
);

AutoPlay.propTypes = propTypes;
AutoPlay.defaultProps = defaultProps;

export default AutoPlay;
