import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
// import isNumber from 'lodash/isNumber';

import SVGContainer from './SVGContainer';

import styles from '../../../styles/animations/default-animation.scss';

const propTypes = {
    delay: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    width: null,
    height: null,
    delay: 0,
};

const Base = ({ delay, width, height }) => {
    const size = { width: 1600, height: 1600 };

    const actualWidth = Math.max(width, size.width);
    const actualHeight = Math.max(height, size.height);

    const groupRef = useRef(null);
    const itemsRef = useRef([]);

    const linearRef = useRef(null);
    const linearTopRef = useRef(null);
    const linearBottomRef = useRef(null);

    const gradientRef = useRef(null);
    const backgroundTopRef = useRef(null);
    const backgroundBottomRef = useRef(null);

    const path = [
        'M',
        '800.8',
        '1099c111',
        '0',
        '235.9-83.3',
        '263.7-249.8c29.7-178.5-208.2-346.9-402.5-222C506.3',
        '727.3',
        '509',
        '1099',
        '800.8',
        '1099',
        'z',
    ];

    const colorPairs = [
        { a: '#00ffff', b: '#ffffff' },
        { a: '#00ff00', b: '#ffffff' },
        { a: '#ffff00', b: '#ffffff' },
        { a: '#ff00ff', b: '#ffffff' },
    ];

    const paths = [1, 2, 3, 4, 5].map(() => ({
        value: path
            .map((item) => {
                // console.log(item);
                if (item.match(/^-{0,1}\d+$/)) {
                    // console.log('int');
                    const value = parseInt(item, 10);
                    return anime.random(value - 2, value + 2);
                }
                if (item.match(/^\d+\.\d+$/)) {
                    // console.log('fp');
                    const value = parseFloat(item);
                    return anime.random(value - 2, value + 2);
                }
                return item;
            })
            .join(' '),
    }));

    useEffect(() => {
        const backgroundTimeline = anime.timeline({
            loop: true,
            direction: 'alternate',
            easing: 'easeInOutQuad',
        });

        colorPairs.forEach((colorPair, i) => {
            backgroundTimeline.add(
                {
                    targets: [backgroundTopRef.current],
                    stopColor: colorPair.a,
                    duration: 8000,
                },
                (i + 1) * 8000,
            );
            backgroundTimeline.add(
                {
                    targets: [backgroundBottomRef.current],
                    stopColor: colorPair.b,
                    duration: 8000,
                },
                (i + 1) * 8000,
            );
        });

        // Linear
        const linearTimeline = anime
            .timeline({
                loop: true,
                direction: 'alternate',
                easing: 'spring(1, 80, 10, 0)',
            })
            .add({
                targets: gradientRef.current,
                r: 1400 / 2,
            });

        colorPairs.forEach((colorPair, i) => {
            linearTimeline.add(
                {
                    targets: [linearTopRef.current],
                    stopColor: colorPair.a,
                    duration: 8000,
                },
                (i + 1) * 8000,
            );
            linearTimeline.add(
                {
                    targets: [linearBottomRef.current],
                    stopColor: colorPair.b,
                    duration: 8000,
                },
                (i + 1) * 8000,
            );
        });

        // Gradient size
        anime
            .timeline({
                targets: gradientRef.current,
                loop: true,
                direction: 'alternate',
                easing: 'easeInOutQuad',
                duration: 4000,
            })
            .add(
                {
                    r: 700 / 2,
                },
                0,
            )
            .add(
                {
                    r: 1400 / 2,
                },
                4000,
            )
            .add(
                {
                    cx: actualWidth / 3,
                    cy: actualHeight / 3,
                },
                4000,
            )
            .add(
                {
                    r: 400 / 2,
                },
                8000,
            )
            .add(
                {
                    cx: actualWidth / 1.5,
                    cy: actualHeight / 1.5,
                },
                8000,
            )
            .add(
                {
                    r: 1200 / 2,
                },
                12000,
            )
            .add(
                {
                    cx: actualWidth / 2,
                    cy: actualHeight / 3,
                },
                12000,
            )
            .add(
                {
                    r: 1000 / 2,
                },
                16000,
            )
            .add(
                {
                    cx: actualWidth / 1.5,
                    cy: actualHeight / 3,
                },
                16000,
            );

        // Growing timeline
        const timeline = anime.timeline({
            loop: true,
            targets: groupRef.current,
            direction: 'alternate',
            delay,
            easing: 'easeInOutQuad',
            duration: 4000,
        });

        timeline
            .add({
                scale: 0.8,
                rotate: -10,
            })
            .add({
                scale: 1.3,
                rotate: 10,
            });

        const scaleTimeline = anime.timeline({
            targets: itemsRef.current,
            loop: true,
            direction: 'alternate',
            delay,
            easing: 'easeInOutQuad',
            duration: 4000,
        });

        scaleTimeline
            .add({
                scale: anime.stagger([0.5, 4]),
                duration: 8000,
            })
            .add({
                scale: anime.stagger([2, 0.8]),
            });

        return () => {
            anime.remove([
                groupRef.current,
                linearTopRef.current,
                linearBottomRef.current,
                gradientRef.current,
                backgroundTopRef.current,
                backgroundBottomRef.current,
            ]);
            anime.remove(itemsRef.current);
        };
    }, []);

    return (
        <div className={styles.container}>
            <SVGContainer width={width} height={height} size={size}>
                <g ref={groupRef}>
                    <radialGradient
                        id="SVGID_RAD_BASE"
                        ref={gradientRef}
                        cx={actualWidth / 2}
                        cy={actualHeight / 2}
                        r={700 / 2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop
                            offset="0"
                            ref={backgroundTopRef}
                            stopColor="#00FFFF"
                            stopOpacity="1"
                        />
                        <stop
                            offset="1"
                            ref={backgroundBottomRef}
                            stopColor="#FFFFFF"
                            stopOpacity="0"
                        />
                    </radialGradient>
                    <linearGradient
                        id="SVGID_PATHS"
                        ref={linearRef}
                        gradientUnits="userSpaceOnUse"
                        x1="97.451"
                        y1="219.5463"
                        x2="620.209"
                        y2="219.5463"
                        gradientTransform="matrix(-0.7071 0.7071 -0.7071 -0.7071 653.0718 156.6759)"
                    >
                        <stop ref={linearTopRef} offset="0" stopColor="#00FFFF" />
                        <stop ref={linearBottomRef} offset="1" stopColor="#00FF00" />
                    </linearGradient>
                    <path
                        className="st0"
                        ref={(el) => {
                            itemsRef.current[0] = el;
                        }}
                        fill="url(#SVGID_RAD_BASE)"
                        d={path.join(' ')}
                    />
                    {paths.map((p, i) => (
                        <path
                            key={`base-${i + 1}`}
                            className="st0"
                            ref={(el) => {
                                itemsRef.current[i + 1] = el;
                            }}
                            fill="url(#SVGID_RAD_BASE)"
                            d={p.value}
                            style={{ opacity: 0.5 }}
                        />
                    ))}
                </g>
            </SVGContainer>
        </div>
    );
};

Base.propTypes = propTypes;
Base.defaultProps = defaultProps;

export default Base;
