import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';

import PageMeta from '../partials/PageMeta';

import { setAnimation } from '../hooks/useAnimations';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/text.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Le rire universel',
    },
    title: {
        id: 'content.about.title',
        defaultMessage: 'Un projet de recherche en intelligence artificielle sur le rire',
    },
    description: {
        id: 'content.about.description',
        defaultMessage: 'Lorem ipsum dolor sit amet',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const AboutPage = ({ urlGenerator }) => {
    setAnimation({ name: 'Base' });
    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.info}>
                <h1>
                    <FormattedMessage {...messages.title} />
                </h1>
                <p>
                    <FormattedMessage {...messages.description} />
                </p>
                <p>
                    <FormattedMessage {...messages.description} />
                </p>
                <div className={styles.back}>
                    <Link className={styles.button} to={urlGenerator.route('home')}>
                        Retour
                    </Link>
                </div>
            </div>
        </div>
    );
};

AboutPage.propTypes = propTypes;

const WithUrlGeneratorContainer = withUrlGenerator()(AboutPage);

export default WithUrlGeneratorContainer;
