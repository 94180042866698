/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';
import classNames from 'classnames';

import useCurrentRoute from '../hooks/useCurrentRoute';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/footer.scss';

const messages = defineMessages({
    about: {
        id: 'content.menu.about',
        defaultMessage: 'À propos',
    },
    conditions: {
        id: 'content.menu.conditions',
        defaultMessage: "Conditions d'utilisation",
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    history: AppPropTypes.history.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {};

const Footer = ({ intl, urlGenerator, history }) => (
    <div className={styles.container}>
        <p>
            <Link
                to={urlGenerator.route('about')}
                className={classNames([
                    styles.link,
                    {
                        [styles.current]: useCurrentRoute(
                            urlGenerator,
                            history.location.pathname,
                            'about',
                        ),
                    },
                ])}
            >
                {intl.formatMessage(messages.about)}
            </Link>
            <Link
                to={urlGenerator.route('conditions')}
                className={classNames([
                    styles.link,
                    {
                        [styles.current]: useCurrentRoute(
                            urlGenerator,
                            history.location.pathname,
                            'conditions',
                        ),
                    },
                ])}
            >
                {intl.formatMessage(messages.conditions)}
            </Link>
        </p>
    </div>
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(Footer);
const WithRouter = withRouter(WithUrlGeneratorContainer);

export default injectIntl(WithRouter);
