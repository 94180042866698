import { LOCATION_CHANGE } from 'connected-react-router';
import {
    SET_ERRORS,
    SET_BLOB_URL,
    SET_ANALYSIS,
    SET_ANIMATION,
    SET_VOICES,
    SET_SOUNDS,
} from '../actions/SiteActions';

const initialState = {
    errors: null,
    statusCode: null,
    messages: null,

    analysis: [],
    blobURL: null,
    animation: {
        // name: 'Blob',
    },

    // generated by the computer, from the root props
    voices: [],
    // recordings
    sounds: [],
};

const SiteReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
    case SET_ERRORS:
        return {
            ...state,
            errors: action.payload,
        };
    case SET_BLOB_URL:
        return {
            ...state,
            blobURL: action.payload,
        };
    case SET_ANALYSIS:
        return {
            ...state,
            analysis: [...state.analysis, ...action.payload],
        };
    case SET_ANIMATION:
        return {
            ...state,
            animation: action.payload,
        };
    case SET_VOICES:
        return {
            ...state,
            voices: action.payload,
        };
    case SET_SOUNDS:
        return {
            ...state,
            sounds: action.payload,
        };
    case LOCATION_CHANGE:
        return typeof state.initialLocationChange === 'undefined'
                || !state.initialLocationChange
            ? {
                ...state,
                initialLocationChange: true,
            }
            : {
                ...state,
                errors: null,
                statusCode: null,
                messages: null,
            };
    default:
        return state;
    }
};

export default SiteReducer;
