/* eslint-disable jsx-a11y/label-has-associated-control jsx-a11y/label-has-for */
import React, {
    useEffect, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import Select from 'react-select';

import Button from '../buttons/Button';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/sound-form.scss';

const messages = defineMessages({
    genderLabel: {
        id: 'content.playback.labels.gender',
        defaultMessage: 'Genre',
    },
    locationLabel: {
        id: 'content.playback.labels.location',
        defaultMessage: 'Localisation',
    },
    gender: {
        id: 'content.playback.questions.gender',
        defaultMessage: 'Genre',
    },
    location: {
        id: 'content.playback.questions.location',
        defaultMessage: 'Localisation',
    },
    address: {
        id: 'content.playback.questions.address',
        defaultMessage: 'Adresse, Ville, Pays',
    },
    merci: {
        id: 'content.playback.questions.thanks',
        defaultMessage: 'Merci!',
    },
});

const selectOptions = [
    { value: 'male', label: { id: 'content.gender.male' } },
    { value: 'female', label: { id: 'content.gender.female' } },
    { value: 'nonbinary', label: { id: 'content.gender.nonbinary' } },
];

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    location: PropTypes.object, // eslint-disable-line
    onGenderChange: PropTypes.func.isRequired,
    onLocationChange: PropTypes.func.isRequired,
};

const defaultProps = {
    location: {
        loading: false,
        latitude: null,
        longitude: null,
    },
};

const SoundForm = ({
    intl, location, onGenderChange, onLocationChange,
}) => {
    // Location
    const [requested, setRequested] = useState(false);
    const [accepted, setAccepted] = useState(null);
    const watchRef = useRef(null);

    const onClickRequest = useCallback(() => {
        setRequested(true);
    });

    const onChangeAddress = useCallback((e) => {
        onLocationChange({
            loading: false,
            error: null,
            address: e.target.value,
        });
    });

    const onSuccess = (e) => {
        onLocationChange({
            loading: false,
            address: null,
            accuracy: e.coords.accuracy,
            altitude: e.coords.altitude,
            altitudeAccuracy: e.coords.altitudeAccuracy,
            heading: e.coords.heading,
            latitude: e.coords.latitude,
            longitude: e.coords.longitude,
            speed: e.coords.speed,
            timestamp: e.timestamp,
        });
    };

    const onError = (error) => {
        onLocationChange(oldState => ({ ...oldState, loading: false, error }));
        if (error.code === error.PERMISSION_DENIED) {
            setAccepted(false);
        } else {
            setAccepted(true);
        }
    };

    useEffect(() => {
        if (requested) {
            navigator.geolocation.getCurrentPosition(onSuccess, onError);
            watchRef.current = navigator.geolocation.watchPosition(onSuccess, onError);
        }
        return () => {
            navigator.geolocation.clearWatch(watchRef.current);
        };
    }, [requested]);

    // Gender

    const onGenderChangeCallback = useCallback(
        (obj) => {
            onGenderChange(obj.value);
        },
        [onGenderChange],
    );

    const options = selectOptions.map(opt => ({
        ...opt,
        label: intl.formatMessage(opt.label),
    }));

    const customStyles = {
        container: provided => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 0,
            color: '#000',
            fontWeight: 'bold',
            outline: 'none !important',
        }),
        control: provided => ({
            ...provided,
            backgroundColor: 'transparent',
            border: 0,
            color: '#000',
            borderRadius: 0,
            outline: 'none !important',
        }),
        placeholder: provided => ({
            ...provided,
            color: '#000',
        }),
        dropdownIndicator: provided => ({
            ...provided,
            color: '#000 !important',
        }),
        indicatorSeparator: provided => ({
            ...provided,
            backgroundColor: '#000',
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#000' : 'transparent',
        }),
        menu: provided => ({
            ...provided,
            backgroundColor: '#FFF',
        }),
    };

    return (
        <div className={styles.container}>
            {accepted === false ? (
                <div className={styles.formGroup}>
                    <label htmlFor="address">
                        <span className={styles.label}>
                            <FormattedMessage {...messages.locationLabel} />
                        </span>
                        <input
                            id="address"
                            className={styles.input}
                            type="text"
                            placeholder={intl.formatMessage(messages.address)}
                            onBlur={onChangeAddress}
                        />
                    </label>
                </div>
            ) : (
                <Button className={styles.button} onClick={onClickRequest}>
                    {location.latitude && location.longitude ? (
                        <span>
                            {location.latitude.toFixed(2)}
                            {' '}
                            {location.longitude.toFixed(2)}
                        </span>
                    ) : (
                        <FormattedMessage {...messages.location} />
                    )}
                    {requested && !location.latitude ? '...' : null}
                </Button>
            )}
            <div className={styles.formGroup}>
                <div htmlFor="gender">
                    <span className={styles.label}>
                        <FormattedMessage {...messages.genderLabel} />
                    </span>
                    <Select
                        id="gender"
                        className={styles.select}
                        placeholder={intl.formatMessage(messages.gender)}
                        options={options}
                        styles={customStyles}
                        onChange={onGenderChangeCallback}
                    />
                </div>
            </div>
        </div>
    );
};

SoundForm.propTypes = propTypes;
SoundForm.defaultProps = defaultProps;

export default injectIntl(SoundForm);
