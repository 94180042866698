import React, { useRef } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';

import ArrowIcon from '../icons/Arrow';
import useClearMedia from '../hooks/useClearMedia';
import { endTextAnimation, setAnimation } from '../hooks/useAnimations';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/end.scss';

const messages = defineMessages({
    voice: {
        id: 'content.end.voice',
        defaultMessage: 'Merci pour votre participation subjugante!',
    },
    thanks: {
        id: 'content.end.thanks',
        defaultMessage: 'Merci!',
    },
    retry: {
        id: 'content.end.retry',
        defaultMessage: 'Rire à nouveau',
    },
    back: {
        id: 'content.end.back',
        defaultMessage: 'Recommencer',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    intl: AppPropTypes.intl.isRequired,
};

const defaultProps = {};

const End = ({ intl, urlGenerator }) => {
    useClearMedia();

    const magentaRef = useRef([]);
    const cyanRef = useRef([]);
    const yellowRef = useRef([]);

    // Get the title by individual letters
    const title = intl
        .formatMessage(messages.thanks)
        .replace(/\s/g, '\u00A0') // empty space replacement
        .split('');

    endTextAnimation({ magentaRef, cyanRef, yellowRef });

    setAnimation({ name: 'Background' });

    return (
        <div className={styles.container}>
            <div className={styles.section} />
            <div className={styles.section}>
                <h1 className={styles.title}>
                    {title.map((letter, i) => (
                        <span
                            key={`${letter}-${i + 1}`}
                            className={styles.letter}
                            ref={(el) => {
                                cyanRef.current[i] = el;
                            }}
                        >
                            {letter}
                        </span>
                    ))}
                </h1>
                <h1 className={styles.title}>
                    {title.map((letter, i) => (
                        <span
                            key={`${letter}-${i + 1}`}
                            className={styles.letter}
                            ref={(el) => {
                                yellowRef.current[i] = el;
                            }}
                        >
                            {letter}
                        </span>
                    ))}
                </h1>

                <h1 className={styles.title}>
                    {title.map((letter, i) => (
                        <span
                            key={`${letter}-${i + 1}`}
                            className={styles.letter}
                            ref={(el) => {
                                magentaRef.current[i] = el;
                            }}
                        >
                            {letter}
                        </span>
                    ))}
                </h1>
            </div>
            <div className={styles.section}>
                <p />
                <Link
                    className={classNames([styles.button, styles.back])}
                    to={urlGenerator.route('record')}
                >
                    <span>
                        <ArrowIcon />
                    </span>
                    <span>
                        <FormattedMessage {...messages.back} />
                    </span>
                </Link>
            </div>
        </div>
    );
};

End.propTypes = propTypes;
End.defaultProps = defaultProps;

const WithIntlContainer = injectIntl(End);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);

export default WithUrlGeneratorContainer;
