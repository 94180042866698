/* eslint-disable max-len, no-unused-vars */
import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';
import { interpolateHslLong } from 'd3-interpolate';
import sample from 'lodash/sample';
import shuffle from 'lodash/shuffle';

import ComplexLinked from './ComplexLinked';

import styles from '../../../styles/animations/layered-animation.scss';

const ITEM_COUNT = 15;

const propTypes = {
    layers: PropTypes.number,
    variables: PropTypes.array, // eslint-disable-line
};

const defaultProps = {
    layers: 2,
    variables: [...Array(ITEM_COUNT).keys()].map(() => {
        const shapes = [
            'M815.2,243.1c-56.6-56.6-162.6-77.8-261.6-7.1c-106.1,75.8-70.7,282.8,91.9,318.2 C775.9,582.6,963.9,391.8,815.2,243.1z',
            'M793.2,342.1c-26.5-50-80.8-85.8-162.3,4.5c-76.8,85.1,1.5,145.1,111.5,143.7 C800.7,489.5,856.8,462.3,793.2,342.1z',
            'M861.5,340.4c-26.5-50-169.7-155.6-268.7-84.9c-106.1,75.8-70.7,183.8,21.2,247.5 C699.8,562.5,925.1,460.6,861.5,340.4z',
            'M797.3,234.7c-56-56.4-162.8-79.3-261.8-8.6c-106.1,75.8-70.7,280.9,90.5,316.8 C755.6,571.9,944.3,382.8,797.3,234.7z',
        ];
        const colors = shuffle(['#ff00ff', '#00ffff', '#FFFF00', '#00FF00']); // '#0000ff', '#f7931e',
        const shuffledColors = shuffle(colors);
        const startColor = sample(shuffledColors);
        const stopColor = sample(shuffledColors.filter(c => c !== startColor));
        const nextShape = sample(shapes); // .filter(s => s !== previousShape.current));
        const moveUnit = anime.random(5, 10);
        const direction = Math.random() > 0.5 ? 1 : -1;
        const destinationX = anime.random(1, 15) * moveUnit * direction;
        const destinationY = anime.random(1, 15) * moveUnit * direction;

        return {
            moveUnit: anime.random(5, 10),
            direction: Math.random() > 0.5 ? 1 : -1,
            startColor,
            stopColor,
            colorScale: interpolateHslLong(startColor, stopColor),
            rotation: anime.random(-90, 90),
            nextShape,
            destinationX,
            destinationY,
        };
    }),
};

const Complex = ({ variables, layers, ...otherProps }) => (
    <div className={styles.layered}>
        {[...Array(layers).keys()].reverse().map(i => (
            <ComplexLinked
                key={`complex-layer-${i + 1}`}
                variables={variables}
                {...otherProps}
                name={`layer-${i}`}
                delay={i * 1000}
            />
        ))}
    </div>
);

Complex.propTypes = propTypes;
Complex.defaultProps = defaultProps;

export default Complex;
