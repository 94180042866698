import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import anime from 'animejs';

import styles from '../../../styles/icons/default.scss';

const propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    width: 153.3,
    height: 134,
    className: null,
};

const MapBlob = ({ width, height, className }) => {
    const svgRef = useRef(null);

    useEffect(() => {
        anime
            .timeline({ loop: true, direction: 'alternate' })
            .add({
                targets: [svgRef.current],
                duration: 8000,
                scale: 0.9,
                rotate: -360,
                easing: 'easeInOutSine',
            })
            .add({
                targets: [svgRef.current],
                rotate: 360,
                duration: 8000,
                scale: 1.2,
                easing: 'easeInOutSine',
            });
        return () => {
            anime.remove([svgRef.current]);
        };
    }, []);

    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${width}px`}
            height={`${height}px`}
            viewBox={`0 0 ${width} ${height}`}
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
            // style={{ border: '2px solid black' }}
            ref={svgRef}
        >
            <g>
                <path
                    fill="#ffff00"
                    d="M7.3,65.4c0-27.3,20.5-58,61.4-64.8c43.8-7.3,85.2,51.1,54.5,98.9C98.6,137.7,7.3,137.1,7.3,65.4z"
                    opacity="0.8"
                />
                <path
                    fill="#A0FF5F"
                    d="M29.5,79.5c-2.1-24,12.4-51.2,53.5-54.8c42.1-3.7,66,45,37.1,84.9C99,138.6,34.7,140.9,29.5,79.5z"
                    opacity="0.6"
                />
                <path
                    fill="#00FFFF"
                    d="M40.4,99.9c-5.7-18.4-1.2-40.2,40.2-38.1c39,2,34.6,35.3,7.7,61.5C74.1,137.3,54.1,144.3,40.4,99.9z"
                    opacity="0.5"
                />
            </g>
        </svg>
    );
};

MapBlob.propTypes = propTypes;
MapBlob.defaultProps = defaultProps;

export default MapBlob;
