import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';
import classNames from 'classnames';

import useCurrentRoute from '../hooks/useCurrentRoute';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/header.scss';

const messages = defineMessages({
    title: {
        id: 'content.home.title',
        defaultMessage: "L'éclat du rire",
    },
    description: {
        id: 'content.home.description',
        defaultMessage: 'Explorer',
    },
    listen: {
        id: 'content.menu.listen.title',
        defaultMessage: 'Écouter',
    },
    listenDescription: {
        id: 'content.menu.listen.description',
        defaultMessage: 'le rire universel',
    },
    explore: {
        id: 'content.menu.explore.title',
        defaultMessage: 'Explorer',
    },
    exploreDescription: {
        id: 'content.menu.explore.description',
        defaultMessage: 'les rires du monde',
    },
    participate: {
        id: 'content.menu.participate.title',
        defaultMessage: 'Donner',
    },
    participateDescription: {
        id: 'content.menu.participate.description',
        defaultMessage: 'votre rire',
    },
    fr: {
        id: 'content.language.en',
        defaultMessage: 'english',
    },
    en: {
        id: 'content.language.fr',
        defaultMessage: 'français',
    },
});

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
    history: AppPropTypes.history.isRequired,
    intl: AppPropTypes.intl.isRequired,
};

const defaultProps = {};

const Header = ({ history, urlGenerator, intl }) => (
    <div className={styles.container}>
        <div>
            <Link
                className={classNames([
                    styles.home,
                    {
                        [styles.disabled]: useCurrentRoute(
                            urlGenerator,
                            history.location.pathname,
                            'home',
                        ),
                    },
                ])}
                to={urlGenerator.route('home')}
            >
                <span className={styles.title}>
                    <FormattedMessage {...messages.title} />
                </span>
                <span>
                    <FormattedMessage {...messages.description} />
                </span>
            </Link>
        </div>
        <p>
            <Link
                className={classNames([
                    styles.link,
                    {
                        [styles.current]: useCurrentRoute(
                            urlGenerator,
                            history.location.pathname,
                            'listen',
                        ),
                    },
                ])}
                to={urlGenerator.route('listen')}
            >
                <span className={styles.title}>
                    <FormattedMessage {...messages.listen} />
                </span>
                <span>
                    <FormattedMessage {...messages.listenDescription} />
                </span>
            </Link>
            <Link
                className={classNames([
                    styles.link,
                    {
                        [styles.current]: useCurrentRoute(
                            urlGenerator,
                            history.location.pathname,
                            'map',
                        ),
                    },
                ])}
                to={urlGenerator.route('map')}
            >
                <span className={styles.title}>
                    <FormattedMessage {...messages.explore} />
                </span>
                <span>
                    <FormattedMessage {...messages.exploreDescription} />
                </span>
            </Link>
            <Link
                className={classNames([
                    styles.link,
                    {
                        [styles.current]: useCurrentRoute(
                            urlGenerator,
                            history.location.pathname,
                            'record',
                        ),
                    },
                ])}
                to={urlGenerator.route('record')}
            >
                <span className={styles.title}>
                    <FormattedMessage {...messages.participate} />
                </span>
                <span>
                    <FormattedMessage {...messages.participateDescription} />
                </span>
            </Link>
        </p>
        <div className={classNames([styles.language])}>
            <a
                className={classNames([styles.bottomLinedLink])}
                href={`/${intl.locale === 'fr' ? 'en' : 'fr'}`}
            >
                <span className={styles.title}>
                    <FormattedMessage {...messages[intl.locale]} />
                </span>
            </a>
        </div>
    </div>
);

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(Header);
const WithRouter = withRouter(WithUrlGeneratorContainer);

export default injectIntl(WithRouter);
