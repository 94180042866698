import { useEffect, useState } from 'react';
// import RecordRTCPromisesHandler from 'recordrtc';

import {
    // MEDIA_CANNOT_RECORD,
    MEDIA_INIT,
    MEDIA_WAITING_TO_RECORD,
    MEDIA_RECORDING_STARTED,
    // MEDIA_RECORDING_PAUSED,
    MEDIA_RECORDING_ENDED,
} from '../mediaStates';

import useInterval from './useInterval';

export default ({ started = false, time = 10, delay = 5 }) => {
    const [recorderState, setRecorderState] = useState(MEDIA_INIT);
    const [waitTime, setWaitTime] = useState(delay);

    useEffect(() => {
        if (started && recorderState === MEDIA_INIT) {
            setWaitTime(delay);
            setRecorderState(MEDIA_WAITING_TO_RECORD);
        }
    }, [started, recorderState]);

    useInterval(
        () => {
            setWaitTime(c => c - 1);
            if (waitTime < 1) {
                setWaitTime(time);
                setRecorderState(MEDIA_RECORDING_STARTED);
            }
        },
        recorderState === MEDIA_WAITING_TO_RECORD ? 1000 : null,
    );

    useInterval(
        () => {
            setWaitTime(r => r - 1);
            if (waitTime < 1) {
                setWaitTime(-1);
                setRecorderState(MEDIA_RECORDING_ENDED);
            }
        },
        recorderState === MEDIA_RECORDING_STARTED ? 1000 : null,
    );

    return [recorderState, waitTime];
};
