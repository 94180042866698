/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAudio } from 'react-use';

import AutoPlayIcon from '../icons/AutoPlay';

import styles from '../../../styles/partials/player.scss';

const propTypes = {
    src: PropTypes.string,
    playing: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
};

const defaultProps = {
    src: null,
};

const AutoPlayer = ({
    src, playing, onClick, onComplete,
}) => {
    // const [playedOnce, setPlayedOnce] = useState(false);

    const [audio, state, controls] = useAudio({
        src,
        autoPlay: false,
        loop: false,
    });

    useEffect(() => {
        if (playing) {
            controls.play();
        } else {
            controls.pause();
        }
    }, [playing, controls]);

    useEffect(() => {
        if (playing && state.isPlaying === false) {
            onComplete();
        }
    }, [playing, state, onComplete]);

    // console.log(src, state.isPlaying);
    // const onButtonClick = useCallback(() => {
    //     if (!playedOnce && src !== null) {
    //         setPlayedOnce(true);
    //         controls.play();
    //     } else {
    //         onClick();
    //     }
    // }, [playedOnce, setPlayedOnce, state, src, controls]);

    // const onSrcChange = useCallback(() => {
    //     if (src === null) {
    //         controls.pause();
    //     } else if (playedOnce && !state.isPlaying) {
    //         controls.play();
    //     }
    // }, [playedOnce, state, controls, src]);

    // useEffect(() => {
    //     onSrcChange();
    // }, [src]);

    // eslint-disable-next-line
    const color = state.isPlaying ? '#FFFF00' : '#CCCCCC';
    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <button className={styles.button} type="button" onClick={onClick}>
                    <AutoPlayIcon highlights={color} />
                </button>
                <div>{audio}</div>
            </div>
        </div>
    );
};

AutoPlayer.propTypes = propTypes;
AutoPlayer.defaultProps = defaultProps;

export default AutoPlayer;
