import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, FormattedMessage } from 'react-intl';

import Player from './Player';
import Flag from '../icons/Flag';
import MapBlobIcon from '../icons/MapBlob';

import styles from '../../../styles/partials/map-player.scss';

const messages = defineMessages({
    flag: {
        id: 'content.map.flag',
        defaultMessage: 'Signaler ce contenu',
    },
});

const propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    audio: PropTypes.shape({
        url: PropTypes.string.isRequired,
    }),
    gender: PropTypes.string,
    date: PropTypes.string,
    flag: PropTypes.func,
    onClose: PropTypes.func.isRequired,
};

const defaultProps = {
    id: null,
    audio: {},
    gender: null,
    date: null,
    flag: null,
};

const MapPlayer = ({
    id, audio, gender, date, flag, onClose,
}) => {
    const onClick = useCallback(() => {
        if (flag !== null) {
            flag(id);
        }
        onClose();
    }, [flag, onClose]);
    return (
        <div className={classNames([styles.container, { [styles.visible]: id !== null }])}>
            <div className={styles.section}>
                <Player src={audio.url} />
                <p>{gender}</p>
                <p>{date}</p>
                <button className={styles.button} type="button" onClick={onClick}>
                    <Flag />
                    <span>
                        <FormattedMessage {...messages.flag} />
                    </span>
                </button>
            </div>
            <div className={styles.background}>
                <MapBlobIcon />
            </div>
        </div>
    );
};

MapPlayer.propTypes = propTypes;
MapPlayer.defaultProps = defaultProps;

export default MapPlayer;
