/* global GOOGLE_MAPS_API_KEY */
import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import MarkerClusterer from '@google/markerclustererplus';

import markerIcon from '../../../img/marker.png';

import { setScriptLoaded as setScriptLoadedAction } from '../../actions/LayoutActions';

import mapStyles from '../../lib/map.json';

import styles from '../../../styles/partials/map.scss';

const propTypes = {
    items: PropTypes.arrayOf(PropTypes.object), // eslint-disable-line
    mapConfig: PropTypes.shape({
        zoom: PropTypes.number,
        center: PropTypes.shape({
            lat: PropTypes.number.isRequired,
            lng: PropTypes.number.isRequired,
        }),
    }),
    loaded: PropTypes.bool.isRequired,
    setCurrentItem: PropTypes.func.isRequired,
    setScriptLoaded: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    items: [],
    mapConfig: {
        zoom: 3,
        center: {
            // Montréal, could be something else
            lat: 45.52,
            lng: -73.7,
        },
        disableDefaultUI: true,
        styles: mapStyles,
        minZoom: 3,
        // maxZoom: 15,
    },
    className: null,
};

const Maps = ({
    items, mapConfig, loaded, setCurrentItem, setScriptLoaded, className,
}) => {
    const mapRef = useRef(null); // The element
    const clusterer = useRef(null);
    const map = useRef(null);
    const markers = useRef([]);

    // Create the markers
    const createMarkers = useCallback(
        (marks) => {
            console.log('ok what now', marks); // eslint-disable-line
            if (marks && marks.length > 0) {
                marks.forEach((item, i) => {
                    const marker = new window.google.maps.Marker({
                        position: { lat: item.lat, lng: item.lng },
                        icon: markerIcon,
                    });
                    marker.addListener('click', () => {
                        const position = marker.getPosition();
                        console.log(i, item, position); // eslint-disable-line
                        map.current.setCenter(position);
                        setCurrentItem(item, position);
                    });
                    markers.current.push(marker);
                });
                clusterer.current = new MarkerClusterer(map.current, markers.current, {
                    gridSize: 50,
                    maxZoom: 15,
                    imagePath: '/img/maps/m', // This is in public folder
                });
            }
        },
        [map, clusterer, markers],
    );

    // Create the map
    const createMap = useCallback(() => {
        map.current = new window.google.maps.Map(mapRef.current, mapConfig);
        createMarkers(items);
        map.current.addListener('mousedown', () => {
            setCurrentItem(null, null);
        });
    }, [map, mapRef, mapConfig, items]);

    // Delete the markers
    // const deleteMarkers = useCallback(() => {
    //     markers.current.forEach((item) => {
    //         item.setMap(null);
    //     });
    //     markers.current = [];
    // }, [map, mapRef, markers]);

    // Load the map
    useEffect(() => {
        if (!loaded) {
            const googleMapScript = document.createElement('script');
            googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`;
            window.document.body.appendChild(googleMapScript);
            googleMapScript.addEventListener('load', () => {
                createMap();
                setScriptLoaded('googleMaps');
            });
        } else if (loaded && map.current === null) {
            createMap();
        }
    }, [loaded, map]);

    // Always delete and recreate the markers
    useEffect(() => {
        console.log('heyyyy', map, items, markers); // eslint-disable-line
        if (map.current !== null && items.length > 0 && markers.current.length === 0) {
            console.log('do it'); // eslint-disable-line
            // deleteMarkers();
            createMarkers(items);
        }
    }, [map, items, markers]);

    console.log('IT LIVES', items); // eslint-disable-line

    return (
        // You must set the container width / height explicitly
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <div
                id="map"
                ref={mapRef}
                style={{ position: 'relative', width: '100%', height: '100%' }}
            />
        </div>
    );
};

Maps.propTypes = propTypes;
Maps.defaultProps = defaultProps;

const WithStateContainer = connect(
    ({ layout: { scripts } }) => ({
        loaded: !!scripts.googleMaps,
    }),
    dispatch => ({
        setScriptLoaded: script => dispatch(setScriptLoadedAction(script)),
    }),
)(Maps);

export default WithStateContainer;
