import { useEffect, useState } from 'react';
// import { useUrlGenerator } from '@folklore/react-container'; // Needs updated version

export default (urlGenerator, current, route = 'home', params = {}) => {
    const [active, setActive] = useState(false);
    useEffect(() => {
        if (urlGenerator.route(route, params) === current) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [route, current, setActive]);
    return active;
};
