import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';

import SVGContainer from './SVGContainer';

import styles from '../../../styles/animations/default-animation.scss';

const propTypes = {
    status: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    status: null,
    width: null,
    height: null,
};

const LineAnimation = ({ width, height, status }) => {
    const time = 9300;
    const started = useRef(false);
    const maxHeight = height / 2;
    const strokeWidth = 10;
    const size = { width, height: maxHeight };
    const containerRef = useRef(null);
    const channel1Ref = useRef([]);
    const channel2Ref = useRef([]);
    // const started = true; // status === 'started';
    // console.log(status);
    useEffect(() => {
        if (status === 'started' && started.current === false) {
            started.current = true;

            // anime.set(channel1Ref.current, { opacity: 0 });
            // anime.set(channel2Ref.current, { opacity: 0 });

            // Recording animation
            const animation = anime
                .timeline({
                    duration: 2000,
                    easing: 'spring(1, 50, 30, 3)',
                })
                .add(
                    {
                        targets: channel1Ref.current,
                        opacity: [0, 1],
                        scale: [0.5, 1],
                        stroke: '#00FF00',
                        delay: anime.stagger([0, time]),
                    },
                    0,
                )
                .add(
                    {
                        targets: channel2Ref.current,
                        opacity: [0, 1],
                        scale: [0.5, 1],
                        stroke: '#FF00FF',
                        delay: anime.stagger([250, time + 250]),
                    },
                    0,
                );

            animation.finished.then(() => {
                anime
                    .timeline({
                        targets: channel1Ref.current,
                        easing: 'spring(1, 20, 80, 0)',
                        direction: 'alternate',
                        loop: true,
                        duration: 1000,
                        // delay: (el, i) => 500 + 40 * (i + 1),
                        delay: anime.stagger([0, 2000]),
                    })
                    .add({
                        targets: channel2Ref.current,
                        stroke: '#ffff00',
                        skew: anime.random(2, 4),
                        y1: () => anime.random(0, maxHeight / 2),
                        y2: () => anime.random(maxHeight / 2, maxHeight),
                    })
                    .add({
                        targets: channel1Ref.current,
                        stroke: '#00FFFF',
                        skew: anime.random(2, 4),
                        y1: () => anime.random(0, maxHeight / 2),
                        y2: () => anime.random(maxHeight / 2, maxHeight),
                    })
                    .add({
                        targets: channel2Ref.current,
                        stroke: '#ff00ff',
                        skew: anime.random(4, -4),
                        y1: () => anime.random(0, maxHeight / 2),
                        y2: () => anime.random(maxHeight / 2, maxHeight),
                    })
                    .add({
                        targets: channel1Ref.current,
                        stroke: '#00FF00',
                        skew: anime.random(-2, -4),
                        y1: () => anime.random(0, maxHeight / 2),
                        y2: () => anime.random(maxHeight / 2, maxHeight),
                    })
                    .add({
                        targets: channel1Ref.current,
                        stroke: '#ffff00',
                        y1: () => anime.random(0, maxHeight / 2),
                        y2: () => anime.random(maxHeight / 2, maxHeight),
                    });
            });
        }
        return () => {
            anime.remove(containerRef.current);
            anime.remove(channel1Ref.current);
            anime.remove(channel2Ref.current);
            started.current = false;
        };
    }, [status, started]);

    const iterations = [...Array(Math.round(width / (strokeWidth * 2))).keys()];

    return (
        <div className={styles.container}>
            <SVGContainer width={width} height={height} size={size}>
                <g ref={containerRef}>
                    {iterations.map((i) => {
                        const distance = 20;
                        const length = anime.random(maxHeight / 4, maxHeight - 20);
                        const startX = 0;
                        const startX2 = 15;

                        const offset = anime.random(maxHeight / 8, maxHeight / 10);

                        const halfRest = maxHeight / 2 - length / 2;
                        const halfRest2 = maxHeight / 2 + length / 2;

                        return (
                            <g key={`lines-${i}`}>
                                <line
                                    ref={(el) => {
                                        channel1Ref.current[i] = el;
                                    }}
                                    fill="none"
                                    stroke="#00FF00"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    x1={startX + i * distance}
                                    y1={halfRest}
                                    x2={startX + i * distance}
                                    y2={halfRest2}
                                />
                                <line
                                    ref={(el) => {
                                        channel2Ref.current[iterations.length + i] = el;
                                    }}
                                    fill="none"
                                    stroke="#FF00FF"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="10"
                                    x1={startX2 + i * distance}
                                    y1={halfRest + offset}
                                    x2={startX2 + i * distance}
                                    y2={halfRest2 - offset}
                                />
                            </g>
                        );
                    })}
                </g>
            </SVGContainer>
        </div>
    );
};

LineAnimation.propTypes = propTypes;
LineAnimation.defaultProps = defaultProps;

export default LineAnimation;
