import React, { useEffect, useCallback, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';

import { getSounds, flag } from '../../api';
import MapPartial from '../partials/Map';
import PageMeta from '../partials/PageMeta';
import MapPlayer from '../partials/MapPlayer';

import { setAnimation } from '../hooks/useAnimations';

import { setSounds as setSoundsAction } from '../../actions/SiteActions';

import styles from '../../../styles/pages/worldmap.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Enregistreuse de rires',
    },
});

const propTypes = {};

const WorldMapPage = () => {
    const [currentSound, setCurrentSound] = useState(null);

    const dispatch = useDispatch();
    const setSounds = useCallback(media => dispatch(setSoundsAction(media)), [dispatch]);
    const sounds = useSelector(s => s.site.sounds);

    // Set the current player
    const setSound = useCallback(
        (item, position) => {
            setCurrentSound({
                ...item,
                ...position,
            });
        },
        [setCurrentSound],
    );

    // Close the player
    const onClose = useCallback(() => {
        setCurrentSound(null);
    }, [setCurrentSound]);

    // Refresh data in case
    useEffect(() => {
        getSounds().then((its) => {
            if (its && its.sounds && its.sounds.length > 0) {
                setSounds(its.sounds.filter(s => s.lat && s.lng && s.audio));
            }
        });
    }, []);

    setAnimation({ name: 'Base' });

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div className={styles.full}>
                <MapPartial items={sounds} setCurrentItem={setSound} />
                {currentSound !== null && currentSound.audio ? (
                    <MapPlayer {...currentSound} flag={flag} onClose={onClose} />
                ) : null}
            </div>
        </div>
    );
};

WorldMapPage.propTypes = propTypes;

const WithIntlContainer = injectIntl(WorldMapPage);

export default WithIntlContainer;
