import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#22b573',
    className: null,
};

const Pause = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="60px"
        height="69.2px"
        viewBox="0 0 60 69.2"
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <g>
                <path
                    fill={color}
                    d="M0,62.3c0,3.8,3.1,6.9,6.9,6.9h9.2c3.8,0,6.9-3.1,6.9-6.9V6.9C23.1,3.1,20,0,16.2,0H6.9C3.1,0,0,3.1,0,6.9V62.3z"
                />
            </g>
            <g>
                <path
                    fill={color}
                    d="M43.8,0c-3.8,0-6.9,3.1-6.9,6.9v55.4c0,3.8,3.1,6.9,6.9,6.9h9.2c3.8,0,6.9-3.1,6.9-6.9V6.9C60,3.1,56.9,0,53.1,0H43.8z"
                />
            </g>
        </g>
    </svg>
);

Pause.propTypes = propTypes;
Pause.defaultProps = defaultProps;

export default Pause;
