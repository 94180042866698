import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#000000',
    className: null,
};

const Flag = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="7.9px"
        height="10.6px"
        viewBox="0 0 7.9 10.6"
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <polygon
                fill="none"
                stroke={color}
                strokeWidth="0.2526"
                strokeMiterlimit="10"
                points="0.2,1.6 1.2,7 5,6.2 3,1.1 "
            />
            <polygon
                fill="none"
                stroke={color}
                strokeWidth="0.2526"
                strokeMiterlimit="10"
                points="7.7,5.5 6.8,0.1 2.7,0.5 4.8,5.8 "
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth="0.2526"
                strokeMiterlimit="10"
                x1="0.1"
                y1="1"
                x2="1.9"
                y2="10.6"
            />
        </g>
    </svg>
);

Flag.propTypes = propTypes;
Flag.defaultProps = defaultProps;

export default Flag;
