import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/default.scss';

const propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    color: '#990000',
    size: 13.4,
    className: null,
};

const Cancel = ({ color, size, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width={`${size}px`}
        height={`${size}px`}
        viewBox={`0 0 ${size} ${size}`}
        className={classNames([styles.container, { [className]: className !== null }])}
        xmlSpace="preserve"
    >
        <g>
            <line
                fill="none"
                stroke={color}
                strokeWidth="2.14"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="1.3"
                y1="1.1"
                x2="12.1"
                y2="12.3"
            />
            <line
                fill="none"
                stroke={color}
                strokeWidth="2.14"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                x1="12.3"
                y1="1.3"
                x2="1.1"
                y2="12.1"
            />
        </g>
    </svg>
);

Cancel.propTypes = propTypes;
Cancel.defaultProps = defaultProps;

export default Cancel;
