/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';

import {
    MEDIA_INIT,
    MEDIA_WAITING_TO_RECORD,
    MEDIA_RECORDING_STARTED,
    MEDIA_RECORDING_PAUSED,
    MEDIA_RECORDING_ENDED,
    MEDIA_SENT,
} from '../mediaStates';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/partials/status.scss';

const messages = defineMessages({
    accept: {
        id: 'content.status.accept',
        defaultMessage:
            "Veuillez appuyer sur le bouton et accepter la demande d'écoute du microphone dans votre navigateur",
    },
    laugh: {
        id: 'content.status.laugh',
        defaultMessage: 'Riez, la machine vous écoute...',
    },
    paused: {
        id: 'content.status.paused',
        defaultMessage: 'La machine ne vous écoute plus.',
    },
    ended: {
        id: 'content.status.ended',
        defaultMessage: "C'est terminé!",
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    status: PropTypes.number.isRequired,
    count: PropTypes.number,
};

const defaultProps = {
    count: 0,
};

const Status = ({ intl, status, count = null }) => (
    <div className={styles.container}>
        <div className={styles.section}>
            {status === MEDIA_INIT ? (
                <p className={styles.help}>{intl.formatMessage(messages.accept)}</p>
            ) : null}
            {status === MEDIA_WAITING_TO_RECORD && count > 0 ? (
                <h1 className={styles.title}>{`${count}`}</h1>
            ) : null}
            {status === MEDIA_RECORDING_STARTED ? (
                <h1 className={styles.title}>{intl.formatMessage(messages.laugh)}</h1>
            ) : null}
            {status === MEDIA_RECORDING_PAUSED ? (
                <h1 className={styles.title}>{intl.formatMessage(messages.paused)}</h1>
            ) : null}
            {status === MEDIA_RECORDING_ENDED ? (
                <h1 className={styles.title}>{intl.formatMessage(messages.ended)}</h1>
            ) : null}
            {status === MEDIA_SENT ? (
                <h1 className={styles.title}>{intl.formatMessage(messages.ended)}</h1>
            ) : null}
        </div>
    </div>
);
Status.propTypes = propTypes;
Status.defaultProps = defaultProps;

export default injectIntl(Status);
