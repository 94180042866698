/* eslint-disable max-len */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import styles from '../../../styles/animations/default-animation.scss';

const propTypes = {
    // width: PropTypes.number,
    // height: PropTypes.number,
    size: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    children: PropTypes.node,
};

const defaultProps = {
    // width: window.innerWidth,
    // height: window.innerHeight,
    size: PropTypes.shape({
        width: window.innerWidth,
        height: window.innerWidth,
    }),
    children: null,
};

const SVGContainer = ({ size, children }) => {
    const bigRef = useRef(null);

    // const translateX = size.width < width
    //     ? `${Math.abs(width - size.width) / 2}`
    //     : `${-Math.abs(size.width - width) / 2}`;
    //
    // const translateY = size.height < height
    //     ? `${Math.abs(height - size.height) / 2}`
    //     : `${-Math.abs(size.height - height) / 2}`;

    // console.log(translateX, translateY);

    return (
        <div className={styles.container}>
            <svg
                ref={bigRef}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                width={`${size.width}px`}
                height={`${size.height}px`}
                viewBox={`0 0 ${size.width} ${size.height}`}
                className={styles.svg}
                xmlSpace="preserve"
                style={
                    {
                        // transform: `translateX(${translateX}px) translateY(${translateY}px)`,
                    }
                }
            >
                {children}
            </svg>
        </div>
    );
};

SVGContainer.propTypes = propTypes;
SVGContainer.defaultProps = defaultProps;

export default SVGContainer;
