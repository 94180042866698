import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setBlobURL as setBlobURLAction } from '../../actions/SiteActions';

export default () => {
    const dispatch = useDispatch();
    const setBlobURL = useCallback(media => dispatch(setBlobURLAction(media)), [dispatch]);
    useEffect(() => {
        setBlobURL(null);
    }, []);
};
