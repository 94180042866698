import React from 'react';
// import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router';
import { withUrlGenerator } from '@folklore/react-container';

import * as AppPropTypes from '../lib/PropTypes';
import MainLayout from './layouts/Main';
import HomePage from './pages/Home';
import RecordPage from './pages/Record';
import PlaybackPage from './pages/Playback';
import EndPage from './pages/End';

import AboutPage from './pages/About';
import ConditionsPage from './pages/Conditions';
import ListenPage from './pages/Listen';
import MapPage from './pages/WorldMap';
import ErrorPage from './pages/Error';

import '../../styles/main.global.scss';

const propTypes = {
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {};

const App = ({ urlGenerator }) => (
    <MainLayout>
        <Switch>
            <Route exact path={urlGenerator.route('home')} component={HomePage} />
            <Route exact path={urlGenerator.route('record')} component={RecordPage} />
            <Route exact path={urlGenerator.route('playback')} component={PlaybackPage} />
            <Route exact path={urlGenerator.route('end')} component={EndPage} />

            <Route exact path={urlGenerator.route('about')} component={AboutPage} />
            <Route exact path={urlGenerator.route('conditions')} component={ConditionsPage} />
            <Route exact path={urlGenerator.route('listen')} component={ListenPage} />
            <Route exact path={urlGenerator.route('map')} component={MapPage} />
            <Route path="*" component={ErrorPage} />
        </Switch>
    </MainLayout>
);

App.propTypes = propTypes;
App.defaultProps = defaultProps;

export default withUrlGenerator()(App);
