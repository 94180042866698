import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { withUrlGenerator } from '@folklore/react-container';

import PageMeta from '../partials/PageMeta';
import { introTextAnimation, setAnimation } from '../hooks/useAnimations';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../../styles/pages/home.scss';

const messages = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Enregistreuse de rires',
    },
    voice: {
        id: 'content.home.voice',
        defaultMessage:
            "Bonjour je suis une enregistreuse de rires. Ha haha... haha haha... haha. Vous pouvez m'aider à m'améliorer un peu?",
    },
    title: {
        id: 'content.home.title',
        defaultMessage:
            'Enregistrez votre rire pour l’avancement de l’art, des technologies et des rires en canne',
    },
    description: {
        id: 'content.home.description',
        defaultMessage:
            'Votre rire sera ajouté à une banque de rires anonymes pour être interprété par une intelligence artificielle supérieure.',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const HomePage = ({ intl, urlGenerator }) => {
    const containerRef = useRef(null);
    const itemsRef = useRef([]);

    // Get the title by individual letters
    const title = intl
        .formatMessage(messages.title)
        .replace(/\s/g, '\u00A0') // replace spaces
        .split('');

    // Simple type animation
    useEffect(() => {
        introTextAnimation(itemsRef);
    }, []);

    setAnimation({ name: 'Base' });

    return (
        <div className={styles.container}>
            <PageMeta title={messages.metaTitle} />
            <div ref={containerRef} className={styles.record}>
                <Link className={styles.link} to={urlGenerator.route('listen')}>
                    <h1 className={styles.title}>
                        {title.map((letter, i) => (
                            <span
                                key={`${letter}-${i + 1}`}
                                className={styles.letter}
                                ref={(el) => {
                                    itemsRef.current[i] = el;
                                }}
                            >
                                {letter}
                            </span>
                        ))}
                    </h1>
                </Link>
            </div>
        </div>
    );
};
HomePage.propTypes = propTypes;

const WithIntlContainer = injectIntl(HomePage);
const WithUrlGeneratorContainer = withUrlGenerator()(WithIntlContainer);

export default WithUrlGeneratorContainer;
