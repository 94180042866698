/* eslint-disable no-nested-ternary */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useAudio } from 'react-use';
import classNames from 'classnames';

import PlayIcon from '../icons/Play';
import PauseIcon from '../icons/Pause';

import styles from '../../../styles/partials/player.scss';

const propTypes = {
    src: PropTypes.string,
    icon: PropTypes.node,
    label: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    src: null,
    icon: null,
    label: null,
    className: null,
};

const Player = ({
    src, icon, label, className,
}) => {
    const buttonRef = useRef(null);
    const CustomIcon = icon;
    const [audio, state, controls] = useAudio({
        src,
    });
    // eslint-disable-next-line
    const color = src !== null ? (state.isPlaying ? '#555555' : '#000000') : '#555555';
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.withLabel]: label !== null,
                    [className]: className !== null,
                },
            ])}
        >
            <div className={styles.section}>
                <button
                    className={styles.button}
                    ref={buttonRef}
                    type="button"
                    onClick={() => {
                        if (state.duration > 0) {
                            if (state.isPlaying) {
                                controls.pause();
                            } else {
                                controls.play();
                            }
                        }
                    }}
                >
                    {icon !== null ? (
                        <CustomIcon />
                    ) : state.isPlaying ? (
                        <PauseIcon color={color} />
                    ) : (
                        <PlayIcon color={color} />
                    )}
                    {label !== null ? <span>{label}</span> : null}
                </button>
                <div>{audio}</div>
            </div>
        </div>
    );
};

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default Player;
