import React from 'react';
import ReactDOM from 'react-dom';
import domready from 'domready';
import 'pepjs';

import Root from './components/Root';

window.time = 0;

window.items = [];
setInterval(() => {
    window.time += 1;
    window.items.push([{ time: window.time, intensity: Math.random() }]);
    if (window.items.length > 200) {
        window.items.shift();
    }
}, 10);

const getRootProps = () => {
    const propsEl = document.getElementById('root-props');
    return propsEl !== null ? JSON.parse(propsEl.innerHTML) || {} : {};
};

const renderRoot = (props) => {
    const rootEl = document.getElementById('root');
    const root = React.createElement(Root, { ...props });
    ReactDOM.render(root, rootEl);
};

const boot = () => {
    const rootProps = getRootProps();
    const promises = [];

    if (typeof window.MediaRecorder === 'undefined') {
        console.log('polyfill'); // eslint-disable-line
        promises.push(import('./vendor/polyfills/media-recorder'));
    }

    if (typeof window.Intl === 'undefined') {
        const { locale = 'fr' } = rootProps;
        promises.push(import(`./vendor/polyfills/intl-${locale}`)); // .then(() => renderRoot(rootProps));
    }

    Promise.all(promises).then(() => {
        renderRoot(rootProps);
    });
};

const ready = (document.readyState || 'loading') !== 'loading';
if (ready) {
    boot();
} else {
    domready(boot);
}
