import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';

import SVGContainer from './SVGContainer';

import styles from '../../../styles/animations/default-animation.scss';

const propTypes = {
    delay: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    width: null,
    height: null,
    delay: 0,
};

const Background = ({ delay, width, height }) => {
    const duration = 8000;
    const gRef = useRef(null);
    const rectRef = useRef([]);
    const gradientRef = useRef([]);

    const backgroundTopRef = useRef(null);
    const backgroundBottomRef = useRef(null);

    const x = 0;
    const y = 0;

    const colorPairs = [
        { a: '#00ffff', b: '#ffffff' },
        { a: '#ffff00', b: '#ffffff' },
        { a: '#ff00ff', b: '#ffffff' },
        { a: '#00ff00', b: '#ffffff' },
        // { a: '#ffffff', b: '#ffffff' },
    ];

    useEffect(() => {
        const backgroundTimeline = anime.timeline({
            loop: true,
            direction: 'alternate',
            easing: 'easeInOutQuad',
            // easing: 'spring(1, 80, 10, 0)',
        });

        colorPairs.forEach((colorPair, i) => {
            backgroundTimeline.add(
                {
                    targets: [backgroundTopRef.current],
                    stopColor: colorPair.a,
                    duration: duration / 1.6,
                },
                (i + 1) * duration,
            );
            backgroundTimeline.add(
                {
                    targets: [backgroundBottomRef.current],
                    stopColor: colorPair.b,
                    duration,
                },
                (i + 1) * duration,
            );
        });

        const timeline = anime.timeline({
            loop: true,
            direction: 'alternate',
            delay,
            easing: 'easeInOutQuad',
        });

        timeline
            .add({
                targets: rectRef.current,
                scale: 1,
                duration: duration / 2,
            })
            .add({
                targets: rectRef.current,
                scale: 2,
                duration: duration / 1.6,
            });

        return () => {
            anime.remove([
                gRef.current,
                rectRef.current,
                backgroundTopRef.current,
                backgroundBottomRef.current,
            ]);
        };
    }, []);

    return (
        <div className={styles.container}>
            <SVGContainer width={width} height={height} size={{ width, height }}>
                <g ref={gRef}>
                    <radialGradient
                        id="SVGID_CIRCLE_"
                        ref={gradientRef}
                        cx={width / 2}
                        cy={height / 2}
                        r={width / 2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" ref={backgroundTopRef} stopColor="#00ff00" />
                        <stop
                            offset="1"
                            ref={backgroundBottomRef}
                            stopColor="#FFFFFF"
                            stopOpacity="0"
                        />
                    </radialGradient>
                    <rect
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        ref={(el) => {
                            rectRef.current = el;
                        }}
                        fill="url(#SVGID_CIRCLE_)"
                    />
                </g>
            </SVGContainer>
        </div>
    );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
