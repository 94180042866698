import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/date-box.scss';

const propTypes = {
    date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    month: PropTypes.string,
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    date: '28',
    month: 'nov',
    color: '#000000',
    className: null,
};

const DateBox = ({
    date, month, color, className,
}) => (
    <div
        className={classNames([styles.container, { [className]: className !== null }])}
        style={{ color, borderColor: color }}
    >
        <h3>{date}</h3>
        <p>{month}</p>
    </div>
);

DateBox.propTypes = propTypes;
DateBox.defaultProps = defaultProps;

export default DateBox;
