import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../../styles/icons/default.scss';

const propTypes = {
    innerColor: PropTypes.string,
    outerColor: PropTypes.string,
    outerStroke: PropTypes.string,
    growColor: PropTypes.string,
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    innerDivider: PropTypes.number,
    outerStrokeWidth: PropTypes.number,
    outerSizeDivider: PropTypes.number,
    percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    animationTime: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    innerColor: '#c00',
    outerColor: '#000',
    outerStroke: '#000',
    growColor: '#000',
    size: 160,
    innerDivider: 4,
    strokeWidth: 4,
    outerStrokeWidth: 4,
    outerSizeDivider: 2,
    percent: 20,
    animationTime: 1,
    className: null,
};

const Ring = ({
    innerColor,
    outerColor,
    outerStroke,
    growColor,
    size,
    strokeWidth,
    innerDivider,
    outerStrokeWidth,
    outerSizeDivider,
    percent,
    animationTime,
    className,
}) => {
    const innerSize = size - 40;
    const stroke = strokeWidth;
    const radius = size / 2;
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const offset = circumference - (parseInt(percent, 10) / 100) * circumference;
    return (
        <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width={`${size}px`}
            height={`${size}px`}
            viewBox={`0 0 ${size} ${size}`}
            className={classNames([styles.container, { [className]: className !== null }])}
            xmlSpace="preserve"
        >
            <circle
                fill={outerColor}
                stroke={outerStroke}
                strokeWidth={outerStrokeWidth}
                cx={size / 2}
                cy={size / 2}
                r={innerSize / outerSizeDivider}
            />
            <circle fill={innerColor} cx={size / 2} cy={size / 2} r={innerSize / innerDivider} />
            <circle
                stroke={growColor}
                fill="transparent"
                strokeDasharray={`${circumference} ${circumference}`}
                strokeWidth={strokeWidth}
                strokeMiterlimit="10"
                strokeDashoffset={offset}
                r={normalizedRadius}
                cx={radius}
                cy={radius}
                style={{
                    transformOrigin: 'center',
                    transform: 'rotate(-90deg)',
                    transition: `all ${animationTime}s linear`,
                }}
            />
        </svg>
    );
};

Ring.propTypes = propTypes;
Ring.defaultProps = defaultProps;

export default Ring;
