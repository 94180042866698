import React from 'react';
import { useSelector } from 'react-redux';
import { Transition, config } from 'react-spring/renderprops';

import Animations from '../animations/index';

import useWindowSize from '../hooks/useWindowSize';

import styles from '../../../styles/partials/background.scss';

const propTypes = {};

const defaultProps = {};

const Background = () => {
    const animation = useSelector(state => state.site.animation);
    const { name, status } = animation;
    const { width, height } = useWindowSize();
    const Animation = name && Animations[name] ? Animations[name] : null;
    return (
        <div className={styles.container}>
            <Transition
                config={config.slow}
                items={Animation}
                from={{ opacity: 0 }}
                enter={{ opacity: 1 }}
                leave={{ opacity: 0 }}
            >
                {show => show
                    && (props => (
                        <div style={props}>
                            {Animation !== null ? (
                                <Animation
                                    delay={0}
                                    {...animation}
                                    status={status}
                                    width={width}
                                    height={height}
                                />
                            ) : null}
                        </div>
                    ))
                }
            </Transition>
        </div>
    );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
