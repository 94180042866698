import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import anime from 'animejs';

import SVGContainer from './SVGContainer';

import styles from '../../../styles/animations/default-animation.scss';

const propTypes = {
    delay: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

const defaultProps = {
    delay: 0,
    width: 0,
    height: 0,
};

const End = ({ delay, width, height }) => {
    const size = { width: 1600, height: 1600 };
    const bigRef = useRef(null);
    const itemsRef = useRef([]);

    const cyanRef = useRef(null);
    const greenRef = useRef(null);
    const gradientRef = useRef(null);

    const path = [
        'M',
        '800.8',
        '1099c111',
        '0',
        '235.9-83.3',
        '263.7-249.8c29.7-178.5-208.2-346.9-402.5-222C506.3',
        '727.3',
        '509',
        '1099',
        '800.8',
        '1099',
        'z',
    ];

    const paths = [path.join(' ')];

    useEffect(() => {
        anime
            .timeline({
                targets: [bigRef.current],
                duration: 2000,
                loop: true,
                direction: 'alternate',
                delay,
                easing: 'steps(100)',
            })
            .add({ scale: 2 })
            .add({
                targets: [gradientRef.current],
                cx: size.width / 1.2,
                cy: size.height / 1.2,
            })
            .add({ scale: 0.9, delay: 10000 })
            .add({ scale: 1 })
            .add({
                targets: [gradientRef.current],
                cx: size.width / 8,
                cy: size.height / 8,
            });
        // .add({
        //     target: itemsRef.current,
        //     d: paths.map(p => ({
        //         value: p,
        //     })),
        // });
        return () => {
            anime.remove(itemsRef.current);
            anime.remove([gradientRef.current, bigRef.current, cyanRef.current, greenRef.current]);
        };
    }, [bigRef]);

    const radius = Math.max(width, height) / 2.2;

    return (
        <div className={styles.container}>
            <SVGContainer width={width} height={height} size={size}>
                <g ref={bigRef}>
                    <radialGradient
                        id="SVGID_END_BASE"
                        ref={gradientRef}
                        cx={size.width / 2} // MOTHERFUCKER RELATIVE TO SIZE WIDTH
                        cy={size.height / 2}
                        r={radius}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset="0" ref={cyanRef} stopColor="#00FFFF" stopOpacity="1" />
                        <stop offset="1" ref={greenRef} stopColor="#0000FF" stopOpacity="0.2" />
                    </radialGradient>
                    {paths.map((p, i) => (
                        <path
                            key={`end-${p}`}
                            ref={(el) => {
                                itemsRef.current[i] = el;
                            }}
                            fill="url(#SVGID_END_BASE)"
                            d={p}
                        />
                    ))}
                </g>
            </SVGContainer>
        </div>
    );
};

End.propTypes = propTypes;
End.defaultProps = defaultProps;

export default End;
