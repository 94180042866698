/**
 * Constants
 */
export const SET_SIZE = 'layout@SET_SIZE';
export const SET_SCRIPT_LOADED = 'layout@SET_SCRIPT_LOADED'; // For the card observer

/**
 * Actions creator
 */
export const setSize = payload => ({
    type: SET_SIZE,
    payload,
});

export const setScriptLoaded = payload => ({
    type: SET_SCRIPT_LOADED,
    payload,
});
